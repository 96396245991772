<template>
    <div v-show="props.show" class="m-horizon-popup-view">
        <slot v-if="delayShow"></slot>
    </div>
</template>

<style lang="less" scoped>
@media screen and (orientation: landscape) {
    .m-horizon-popup-view {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 999;
        overflow: hidden;
        top: 0;
        left: 0;
    }
}

@media screen and (orientation: portrait) {
    .m-horizon-popup-view {
        position: fixed;
        width: 100vh;
        height: 100vw;
        z-index: 999;
        overflow: hidden;
        transform: rotate(90deg);
        transform-origin: top left;
        top: 0;
        left: 100vw;
    }
}
</style>

<script setup>
import { ref, defineProps, defineEmits, watchEffect, nextTick } from 'vue';

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
});

const emits = defineEmits(['update:show']);

let delayShow = ref(false);

watchEffect(() => {
    if (delayShow.value === props.show) {
        return;
    }
    nextTick(() => {
        delayShow.value = props.show;
    });
});
</script>
