<template>
    <div class="trend-chart-view">
        <ChartBox
            :name="props.name"
            :loading="props.loading"
            @mounted="renderChart"
            @resize="onViewResize"
        >
            <template #header>
                <Icon
                    name="expand-o"
                    size="16px"
                    style="margin-top: 7px"
                    @click="isHorizonChartShow = true"
                />
            </template>
        </ChartBox>
        <div
            v-if="isHorizonChartShow"
            :class="{
                'y-axis-ctrl-slider': winOrientation === 0,
                'y-axis-ctrl-slider-landscape': winOrientation !== 0,
            }"
        >
            <Slider
                class="slider"
                v-model="yAxisValueRange"
                :max="yAxisMax + yAxisPadding * 8"
                :min="yAxisMin - yAxisPadding * 8"
                :step="step"
                :vertical="winOrientation !== 0"
                range
                @change="onYAxisValueRangeUpdate"
            ></Slider>
            <Icon
                name="cross"
                size="20px"
                class="toggle-btn"
                @click="isHorizonChartShow = false"
            />
        </div>
        <HorizonPopup v-model:show="isHorizonChartShow">
            <ChartBox
                :name="props.name"
                @mounted="renderBigChart"
                @resize="onBigChartResize"
            >
            </ChartBox>
        </HorizonPopup>
    </div>
</template>

<style lang="less" scoped>
@import '../../../../../common/common.less';
.trend-chart-view {
    position: relative;
    height: 70vw;

    .standard-border;
    .standard-shadow;

    .y-axis-ctrl-slider {
        position: fixed;
        width: 100vw;
        height: 10vw;
        padding: 0;
        bottom: 0;
        left: 0;
        top: 0;
        z-index: 1000;

        .slider {
            margin-top: 5vw;
            width: 72vw;
            margin-left: 14vw;
        }

        .toggle-btn {
            position: absolute;
            color: #999;
            left: 3vw;
            top: 2.5vw;

            &.active {
                color: #333;
            }
        }
    }

    .y-axis-ctrl-slider-landscape {
        position: fixed;
        width: 10vw;
        height: 100vh;
        padding: 0;
        bottom: 0;
        left: 0;
        top: 0;
        z-index: 1000;

        .slider {
            margin-top: 5vw;
            height: 74vh;
            margin-left: 2vw;
        }

        .toggle-btn {
            position: absolute;
            color: #999;
            left: 1vw;
            bottom: 1vw;
            &.active {
                color: #333;
            }
        }
    }
}
</style>

<script setup>
import { ref, computed, defineProps, watch } from 'vue';
import { useRoute } from 'vue-router';

import { Icon, Slider } from 'vant';
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { yAxisLabelFormatter } from '@/common/EChartsTools';
import { formatNumber, formatAxisData } from '@/common/tools';

import ChartBox from '@/components/ChartBox';

import HorizonPopup from '@/components/mobile/HorizonPopup';

const route = useRoute();

const props = defineProps({
    name: {
        type: String,
    },
    data: {
        type: Object,
        default() {
            return {};
        },
    },
    trend: {
        type: Object,
        default() {
            return {};
        },
    },
    loading: {
        type: Boolean,
        default: false,
    },
    percentage: {
        type: Boolean,
        default: false,
    },
});

const xAxisData = computed(() => Object.keys(props.data));

const baseLineData = computed(() => Object.values(props.data));

const trendLineData = computed(() => Object.values(props.trend));

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    CanvasRenderer,
    LabelLayout,
    GridComponent,
    LineChart,
    UniversalTransition,
]);

let echartInstance = null;

let echartBigInstance = null;

function renderChart(dom) {
    echartInstance = echarts.init(dom);
}

function renderBigChart(dom) {
    echartBigInstance = echarts.init(dom);
    setBigChartOption();
}

function onViewResize({ width }) {
    echartInstance.resize({ width });
}

function onBigChartResize({ width }) {
    echartBigInstance.resize({ width });
}

function setChartOption() {
    echartInstance.setOption({
        grid: {
            top: 30,
            left: 60,
            bottom: 30,
            right: 10,
        },
        tooltip: {
            trigger: 'axis',
            position: { top: 0, right: 0 },
            extraCssText: 'z-index:99',
            backgroundColor: 'rgba(255,255,255,0.5)',
            valueFormatter: value => {
                if (!value) {
                    return '-';
                }
                return props.percentage
                    ? `${(value * 100).toFixed(2)}%`
                    : formatNumber(value);
            },
        },
        xAxis: {
            type: 'category',
            data: formatAxisData(
                xAxisData.value,
                route.query.granularity || ''
            ),
        },
        yAxis: {
            type: 'value',
            ...yAxisMaxMinRange.value,
            ...yAxisLabelFormatter(props.percentage ? 'percent' : 'number'),
        },
        series: [
            {
                name: '趋势',
                data: baseLineData.value,
                type: 'line',
                label: {
                    show: true,
                    formatter: series => {
                        let remainder = xAxisData.value.length / 10 + 1;
                        if (series.dataIndex % Math.floor(remainder) !== 0) {
                            return '';
                        }
                        return props.percentage
                            ? `${(series.value * 100).toFixed(2)}%`
                            : formatNumber(series.value);
                    },
                },
                symbolSize: 2,
            },
            {
                name: '7日均线',
                data: trendLineData.value,
                type: 'line',
                showSymbol: false,
                smooth: true,
            },
        ],
    });
}

function setBigChartOption() {
    echartBigInstance.setOption({
        grid: {
            top: 30,
            left: 76,
            bottom: 30,
            right: 10,
        },
        tooltip: {
            trigger: 'axis',
            position: { top: 0, right: 0 },
            backgroundColor: 'rgba(255,255,255,0.5)',
            valueFormatter: value => {
                if (!value) {
                    return '-';
                }
                return props.percentage
                    ? `${(value * 100).toFixed(2)}%`
                    : formatNumber(value);
            },
        },
        xAxis: {
            type: 'category',
            data: formatAxisData(
                xAxisData.value,
                route.query.granularity || ''
            ),
        },
        yAxis: {
            type: 'value',
            ...yAxisMaxMinRange.value,
            ...yAxisLabelFormatter(props.percentage ? 'percent' : 'number'),
        },
        series: [
            {
                name: '趋势',
                data: baseLineData.value,
                type: 'line',
                label: {
                    show: true,
                    formatter: series => {
                        let remainder = xAxisData.value.length / 10 + 1;
                        if (series.dataIndex % Math.floor(remainder) !== 0) {
                            return '';
                        }
                        return props.percentage
                            ? `${(series.value * 100).toFixed(2)}%`
                            : formatNumber(series.value);
                    },
                },
                symbolSize: 2,
            },
            {
                name: '7日均线',
                data: trendLineData.value,
                type: 'line',
                showSymbol: false,
                smooth: true,
            },
        ],
    });
}

let yAxisValueRange = ref([0, 10]);

let yAxisMax = ref(0);

let yAxisMin = ref(10);

let yAxisPadding = ref(0);

let step = ref(1);

const yAxisMaxMinRange = computed(() => {
    return { min: yAxisValueRange.value[0], max: yAxisValueRange.value[1] };
});

function onYAxisValueRangeUpdate(value) {
    setBigChartOption();
}

function getMinMax(arr) {
    let min = arr[0];
    let max = arr[0];
    for (let i = 1; i < arr.length; i++) {
        if (!arr[i]) {
            continue;
        }
        if (arr[i] < min) {
            min = arr[i];
        } else if (arr[i] > max) {
            max = arr[i];
        }
    }
    return { min, max };
}

let isHorizonChartShow = ref(false);

watch(
    () => props.data,
    () => {
        if (baseLineData.value.length < 1) {
            echartInstance.clear();
            return;
        }
        let range = getMinMax([...baseLineData.value, ...trendLineData.value]);
        yAxisMax.value = range.max;
        yAxisMin.value = range.min;
        yAxisPadding.value = (range.max - range.min) / 10;
        yAxisValueRange.value = [
            range.min - yAxisPadding.value * 4,
            range.max + yAxisPadding.value * 4,
        ];
        step.value = (range.max - range.min) / 100;
        setChartOption();
    }
);

let winOrientation = ref(window.orientation);

window.addEventListener('orientationchange', () => {
    winOrientation.value = window.orientation;
});
</script>
