<template>
    <div class="m-mtg-dashboard-view">
        <FilterView />
        <div class="content">
            <TrendChart
                v-for="item in chartList"
                class="item"
                :loading="loading"
                :data="data[item.dataKey]"
                :trend="data[item.trendKey]"
                :percentage="!!item.percentage"
                :name="item.name"
            ></TrendChart>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../../../common/common.less';
.m-mtg-dashboard-view {
    .content {
        padding: 4vw 2vw;
        background-color: #f9f9f9;

        .item {
            margin-bottom: 2.4vw;
        }
    }
}
</style>

<script setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import FilterView from './components/FilterView';
import TrendChart from './components/TrendChart';
import { useDateRange } from '@/common/QueryDateRange';

import { getDashboard } from '@/common/API';

import chartListConf from '@/views/Business/MTGDashboard/charts.json';

const route = useRoute();
const dateRange = useDateRange(route);

let chartList = ref(chartListConf);

let data = ref({});

let loading = ref(false);

function loadData() {
    data.value = {};
    loading.value = true;
    getDashboard(
        dateRange.value.start_time,
        dateRange.value.end_time,
        route.query.granularity || 'day'
    ).then(res => {
        loading.value = false;
        if (res.error_no !== 0) {
            return;
        }
        data.value = res.data;
    });
}
loadData();

watch(dateRange, () => {
    if (route.name === 'mtg_dashboard') {
        loadData();
    }
});
</script>
