<template>
    <div class="filter-view">
        <div class="nav-bar" @click="showFilterPopup">
            <Icon name="wap-nav"></Icon>
            <div class="name">{{ headLabelText }}</div>
        </div>
        <Popup
            v-model:show="isFilterPopupShow"
            position="top"
            :style="{ width: '100%' }"
        >
            <div class="content">
                <div class="title">查询设置</div>
                <Field
                    v-model="dateText"
                    label="区间类型"
                    is-link
                    readonly
                    @click="showDateTypePicker"
                ></Field>
                <Field
                    v-model="customDateRangeText"
                    label="自定日期区间"
                    is-link
                    readonly
                    @click="showCustomDateRangePicker"
                ></Field>
                <Field
                    v-model="granularityText"
                    label="粒度"
                    is-link
                    readonly
                    @click="showGranularityPicker"
                ></Field>
            </div>
            <div class="submit">
                <Button type="primary" block square @click="onQueryClick"
                    >查询</Button
                >
            </div>
        </Popup>
        <!-- 区间类型选择器 -->
        <Popup v-model:show="isDateTypePickerShow" round position="bottom">
            <Picker
                v-model="dateType"
                :columns="dateTypeOpts"
                :show-toolbar="false"
            ></Picker>
        </Popup>
        <!-- 自定日期区间选择器 -->
        <Calendar
            v-model:show="isCustomDateRangePickerShow"
            @confirm="onCustomDateRangePicked"
            :minDate="new Date('2023-01-01')"
            :maxDate="new Date()"
            type="range"
        ></Calendar>
        <!-- 粒度选择器 -->
        <Popup v-model:show="isGranularityPickerShow" round position="bottom">
            <Picker
                v-model="granularity"
                :columns="granularityOptions"
                @confirm="onGranularityPicked"
                @cancel="isGranularityPickerShow = false"
            ></Picker>
        </Popup>
    </div>
</template>

<style lang="less" scoped>
@import '../../../../../common/common.less';

.filter-view {
    margin: 0;
    padding: 2vw;
    background-color: @primary-color;

    .nav-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #fff;

        .name {
            margin-left: 2vw;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            padding: 1vw 2vw 1vw 0;
            font-size: 3.8vw;
            line-height: 4vw;
        }
    }

    .content {
        background-color: #f7f7f7;
        padding: 1vw 2vw 10vw;
        height: 20vh;
        overflow-y: auto;

        .title {
            font-size: 4vw;
            font-weight: bold;
            padding: 3vw 1vw;
        }
    }
}
</style>

<script setup>
import { ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs';

import { Button, Popup, Field, Picker, Icon, Calendar } from 'vant';

import { quickRangeMap } from '@/common/QueryDateRange';
import { isEmptyArray } from '@/common/tools';

const route = useRoute();
const router = useRouter();

let isFilterPopupShow = ref(false);

function showFilterPopup() {
    isFilterPopupShow.value = true;
}

const quickMap = quickRangeMap();
let quickItems = Object.keys(quickMap).map(key => ({ key, ...quickMap[key] }));

const formatDate = date => dayjs(date).format('YYYY-MM-DD');

let dateType = ref(['last7']);
let customDateRange = ref([]);

let dateRes = computed(() =>
    dateType.value[0] !== ''
        ? { type: dateType.value[0] }
        : {
              range: [
                  dayjs(customDateRange.value[0]).valueOf(),
                  dayjs(customDateRange.value[1]).valueOf(),
              ],
          }
);

let dateText = computed(() => {
    if (dateType.value[0] === '') {
        return '';
    }
    let item = quickItems.find(item => item.key === dateType.value[0]);
    return item ? item.label : '最近7天';
});

let customDateRangeText = computed(() => {
    if (isEmptyArray(customDateRange.value)) {
        return '';
    }
    return `${formatDate(customDateRange.value[0])} ~ ${formatDate(
        customDateRange.value[1]
    )}`;
});

let dateTypeOpts = ref(
    quickItems.map(item => ({ text: item.label, value: item.key }))
);

let isDateTypePickerShow = ref(false);

function showDateTypePicker() {
    isDateTypePickerShow.value = true;
}

let isCustomDateRangePickerShow = ref(false);

let headLabelText = computed(() => {
    return dateText.value || customDateRangeText.value || '未设定';
});

function showCustomDateRangePicker() {
    isCustomDateRangePickerShow.value = true;
}

function onCustomDateRangePicked(values) {
    customDateRange.value = values;
    isCustomDateRangePickerShow.value = false;
    dateType.value[0] = '';
}

watch(dateType, () => {
    customDateRange.value = [];
});

let granularity = ref(['day']);

const granularityText = computed(() => {
    return granularityOptions.value.find(
        item => item.value === granularity.value[0]
    ).text;
});

let granularityOptions = ref([
    { text: '日', value: 'day' },
    { text: '周', value: 'week' },
    { text: '相对周', value: '7' },
    { text: '月', value: 'month' },
]);

let isGranularityPickerShow = ref(false);

function showGranularityPicker() {
    isGranularityPickerShow.value = true;
}

function onGranularityPicked({ selectedValues }) {
    // granularity.value = selectedValues;
    isGranularityPickerShow.value = false;
}

function onQueryClick() {
    let res = dateRes.value;
    let dateQuery = {};
    // 写route的规则：如果选择了相对时间区间，则只写入qbt值
    if (res.type) {
        dateQuery.qdt = res.type;
    } else {
        dateQuery.start = res.range[0];
        dateQuery.end = res.range[1];
    }
    router.replace({
        query: { ...dateQuery, granularity: granularity.value[0] },
    });
    isFilterPopupShow.value = false;
}

function initDateType() {
    if (route.query.start && route.query.end) {
        dateType.value[0] = '';
        customDateRange.value = [+route.query.start, +route.query.end];
    } else if (route.query.qdt) {
        customDateRange.value = [];
        dateType.value[0] = route.query.qdt;
    }
    if (route.query.granularity) {
        granularity.value[0] = route.query.granularity;
    }
}
initDateType();
</script>
